<template>
  <v-row v-if="item">
    <v-col cols="12" md="6" class="mx-auto">
      <v-btn
        :class="$store.getters.isThemeDark ? 'grey darken-4' : 'grey lighten-4'"
        :dark="$store.getters.isThemeDark"
        fab
        absolute
        top
        left
        style="left: 2px; top: 2px"
        icon
        @click="$router.go(-1)"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-card
        @click="
          () => {
            if (item.data && item.data.uri) {
              $router.push(item.data.uri);
            } else if (item.data && item.data.url) {
              openUrl(item.data.url);
            }
          }
        "
        outlined
        :class="`mb-6 rounded-lg`"
        dark
      >
        <v-img
          v-if="item.image_url"
          class="ma-1 rounded-lg"
          :src="item.image_url"
        >
        </v-img>
        <v-card-text class="text-right ma-0 pa-2 justify-right">
          {{ item.created_at | fromNow }}
        </v-card-text>
        <v-card-title class="text-h5 mb-1">
          {{ item.title ? item.title : item.body }}
        </v-card-title>
        <v-card-text v-if="item.title">
          {{ item.body }}
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { axios } from "@/plugins/axios";
import { Browser } from "@capacitor/browser";

import bus from "@/bus";
export default {
  name: "notification",
  components: {},
  data() {
    return {
      item: null,
    };
  },
  computed: {},
  methods: {
    openUrl(url) {
      Browser.open({ url: url });
    },
    getAnswer(qstn) {
      let lcl = this.$store.getters.getCurrLocale;
      switch (qstn.correct_option) {
        case "1":
          return lcl == "en"
            ? qstn.option_a
            : qstn.option_a_hi
            ? qstn.option_a_hi
            : qstn.option_a;
          break;
        case "2":
          return lcl == "en"
            ? qstn.option_b
            : qstn.option_b_hi
            ? qstn.option_b_hi
            : qstn.option_b;
          break;
        case "3":
          return lcl == "en"
            ? qstn.option_c
            : qstn.option_c_hi
            ? qstn.option_c_hi
            : qstn.option_c;
          break;
        case "4":
          return lcl == "en"
            ? qstn.option_d
            : qstn.option_d_hi
            ? qstn.option_d_hi
            : qstn.option_d;
          break;

        default:
          return null;
          break;
      }
    },
    fetchItem() {
      this.loading = true;
      axios
        .get("notifications/get/" + this.$route.params.id)
        .then((res) => {
          this.item = res.data;
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
          this.$router.replace({ name: "notifications" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.fetchItem();
  },
};
</script>

